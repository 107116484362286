<template>
  <div>
    <el-main>
      <form-layout :column="optionParent.column" :dataForm.sync="dataObj" ref="formLayout"></form-layout>
    </el-main>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {
update,
  add,
  getDictDetail,
  getDict
 } from "@/api/system/dictbiz";
import { optionParent, optionChild } from "@/option/system/dict";
import {mapGetters} from "vuex";
export default {
  components: { FormLayout, HeadLayout },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
  },
  props: {
    dataObj:{
      type: Object,
      default: () => {
        return {};
      },
    }
  },
  data() {
    return {
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
      {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        },{
        label: this.$t('cip.cmn.btn.celBtn'),
        emit: "head-cancel",
        type: "button",
        icon: ""
      }],
      optionParent,
    }
  },
  created() {
    this.optionParent = this.optionParent(this,this.language)
    if (this.dataObj.id) {
      getDictDetail(this.dataObj.id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    }
  },
  mounted() { },

  methods: {
    headSave(type) {
      this.$refs.formLayout.$refs.form.form.code=this.$refs.formLayout.$refs.form.form.code.trim()
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading()

          add(this.dataObj).then((res) => {
            console.log("res",res.data.data)
            this.dataObj = res.data.data
            this.getValue(this.dataObj.id)
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.$emit("cancel")
          }, error => {
            window.console.log(error);
          }).finally(()=>{
            this.$loading().close()
            this.$emit("cancel")
          })
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })

    },
    getValue(id) {
      getDict(id).then(res => {
        const {data} = res;
        this.dataObj = data.data;
      })
    },
    initData() {
      getDeptTree().then(res => {
        const column = this.findObject(this.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    close() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
